import store from '@/store'
import useNotifications from '@/composables/useNotifications'
import { useRouter } from '@core/utils/utils'
import { ref } from '@vue/composition-api'
import i18n from '@/libs/i18n'
import useCommon from '@/views/organization/useCommon'
import realmConnection from '@/views/habit/realm'

export default function useCommitmentNew() {
  const userData = store.state?.userStore?.userData
  const { showSuccessMessage, showErrorMessage } = useNotifications()
  const { formatDatePicker, createCommitment, getEventId } = useCommon()
  const { router, route } = useRouter()
  const { getItem, updateItem, ObjectId } = realmConnection()

  const commitment = ref({ activity: '', behaviour: {} })
  const event = ref({})
  const selectedWorker = ref('')
  const selectedSupervisor = ref('')
  const isLoading = ref(true)
  const isSubmitting = ref(false)
  const { commitmentId, workerId, eventId } = route.value.params
  const now = new Date()
  const nowWithoutTime = now.setHours(12, 0, 0, 0)
  const query = { _id: ObjectId(commitmentId) }
  const collection = 'commitment'

  if (userData.role === 'supervisor' || userData.role === 'consultant') {
    selectedSupervisor.value = userData.worker_id.$oid
  }

  selectedWorker.value = workerId || ''
  event.value.date = new Date()

  const show = async () => {
    try {
      const item = await getItem({ collection, query })
      if (!item) throw new Error('Item not found')

      commitment.value = item
    } catch (error) {
      console.log(error)
      showErrorMessage(i18n.t('message.commitment_fetch_error'))
    } finally {
      isLoading.value = false
    }
  }

  const submit = async (commitment) => {
    isSubmitting.value = true

    try {
      const payload = {
        behaviour: {
          name: commitment.behaviour.name,
          type: commitment.behaviour.type,
          answer: commitment.behaviour.answer
        },
        completed: true,
        resolutionDate: new Date(nowWithoutTime)
      }
      const action = { $set: payload }

      await updateItem({ collection, query, action })

      showSuccessMessage(i18n.t('message.commitment_updated'))

      updateEventOfCommitment()

      if (commitment.behaviour.newCommitment) {
        const newComm = commitment.behaviour.newCommitment
        newComm.client_id = ObjectId(newComm.client_id)
        newComm.assignee = ObjectId(newComm.assignee)
        newComm.supervisor = ObjectId(selectedSupervisor.value)
        newComm.dueDate = newComm.dueDate ? new Date(`${newComm.dueDate.slice(0, 10)} 12:00:00`) : null
        newComm.confirmation = commitment.confirmation
        newComm.creationDate = new Date(nowWithoutTime)
        createCommitment(newComm)
      }

      router.push({ name: 'habit-commitment-view', params: { commitmentId } })  
    } catch (error) {
      console.log(error)
      showErrorMessage(i18n.t('message.commitment_update_error'))
    } finally {
      isSubmitting.value = false
    }
  }

  const updateEventOfCommitment = async () => {
    const payload = {
      extendedProps: { calendar: "Realizadas" }
    }

    const startDateTime = formatDatePicker(event.value.date)
    const endDateTime = formatDatePicker(event.value.date, 13)

    payload.start = startDateTime
    payload.end = endDateTime

    const id = eventId || await getEventId("commitment", commitmentId)

    const query = { _id: ObjectId(id) }
    const action = { $set: payload }

    try {
      await updateItem({ collection: 'event', query, action })
    } catch (error) {
      console.log(error)
      showErrorMessage(i18n.t('message.event_update_error'))
    }
  }

  return {
    userData,
    show,
    submit,
    commitment,
    event,
    selectedWorker,
    selectedSupervisor,
    isLoading,
    isSubmitting,
  }
}
