<template lang="pug">
.habit-confirmation-wrapper
  b-row(v-if="isLoading")
    b-col(cols="12")
      b-card.py-5
        b-card-body.text-center
          loading(
            :active="true" 
            :is-full-page="false"
            :color="colors.primary"
          )

  div(v-else)
    b-row(v-if="!commitment")
      h1 {{ $t('message.commitment_not_found') }}

    div(v-else)
      validation-observer(ref="eventInfo", tag="form")
        b-row
          b-col(cols="12")
            b-card(:title="commitmentName")
              b-card-text
                | {{ commitment.objective }}
          b-col(cols="12")
            b-card(:title="$t('message.behaviour_evaluation_form')")
              b-form-group
                label {{ $t('label.trabajador') }}
                validation-provider(:name="$t('label.trabajador')", rules="required", v-slot="{ errors }")
                  v-select#v-select-worker(
                    v-model="selectedWorker",
                    :reduce="(workersSupervised) => workersSupervised.value",
                    :disabled="true",
                    :options="workersSupervised",
                    :placeholder="$t('placeholder.select_evaluado')",
                    label="title"
                  )
                  small(class="text-danger") {{ errors[0] }}
              b-form-group
                label {{ $t('label.evaluador') }}
                validation-provider(:name="$t('label.evaluador')", rules="required", v-slot="{ errors }")
                  v-select#v-select-worker(
                    v-model="selectedSupervisor",
                    :reduce="(supervisors) => supervisors.value",
                    :disabled="true",
                    :options="supervisors",
                    :placeholder="$t('placeholder.select_evaluador')",
                    label="title"
                  )
                  small(class="text-danger") {{ errors[0] }}
              b-form-group(:label="$t('label.fetch_confirmation')", label-for="v-date")
                b-form-datepicker#v-date(v-model="event.date", :disabled="true")

      // Activity/Behaviour
      b-row(v-if="commitment.activity")
        b-col(cols="12")
          b-card(:title="commitment.activity")
            b-row
              b-col(cols="12")
                behaviour-question(
                  :behaviour="commitment.behaviour",
                  :allow-add-commitment="!commitment_functionality && isOnline",
                  :clientId="clientId",
                  :workerId="selectedWorker",
                  :activityName="commitment.activity",
                  :excludeNAOption="true",
                  @answered="(answer) => (commitment.behaviour.answer = answer)"
                  @commitmentModified="(newCommitment) => (commitment.behaviour.newCommitment = newCommitment)"
                  @commitmentRemoved="() => (delete commitment.behaviour.newCommitment)"
                )

      // End confirmation
      b-row
        b-col(cols="12")
          b-card(
            :title="$t('message.End_Commitment')",
            :sub-title="$t('message.please_check_all_answers_correct')"
          )
            b-card-text
              b-button(
                :disabled="isSubmitting",
                v-ripple.400="'rgba(255, 255, 255, 0.15)'",
                type="submit",
                variant="primary",
                @click="validateAndSubmit(commitment)"
              )
                | {{ $t('message.submit') }}
</template>

<script>
import { ref, computed, onMounted } from "@vue/composition-api";
import { BRow, BCol, BCard, BCardText, BButton, BFormGroup, BFormSelect, BFormDatepicker, BFormTimepicker, BCardBody } from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import useCommon from '../../../organization/useCommon'
import useCommitmentNew from './useCommitmentNew'
import BehaviourQuestion from '@/views/habit/confirmation/shared/BehaviourQuestion.vue'
import i18n from '@/libs/i18n'
import useNotifications from '@/composables/useNotifications'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from "@validations";
import store from '@/store'
import { VBTooltip } from 'bootstrap-vue'
import Loading from 'vue-loading-overlay'
import EditButton from '@/views/components/Shared/Buttons/EditButton.vue'
import DeleteButton from '@/views/components/Shared/Buttons/DeleteButton.vue'
import { colors } from '@/constants'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BButton,
    BFormGroup,
    BFormSelect,
    BehaviourQuestion,
    vSelect,
    BFormDatepicker,
    BFormTimepicker,
    BCardBody,
    ValidationObserver,
    ValidationProvider,
    EditButton,
    DeleteButton,
    Loading,
  },

  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },

  computed: {
    commitmentName() {
      return i18n.t('commitment') + `: ${this.commitment.title}`
    },
  },

  setup() {
    const { userData, show, submit, event, commitment, selectedWorker, selectedSupervisor, isLoading, isSubmitting } = useCommitmentNew()
    const { getWorkersSupervisedForDropDown, workersSupervised, getSupervisorsForDropDown, supervisors } = useCommon()
    const isOnline = computed(() => store.state.app.isOnline)
    const { showSuccessMessage, showErrorMessage } = useNotifications()
    const clientId = userData.role !== "admin" ? userData.client.$oid : null;
    const eventInfo = ref(null)
    const { commitment_functionality } = JSON.parse(localStorage.getItem('clientData') || '{}')

    const validateEventInfo = () => new Promise((resolve, reject) => {
      eventInfo.value.validate().then(success => {
        if (success) resolve(true)
        else reject()
      })
    })

    const validateAndSubmit = (commitment) => {
      validateEventInfo()
        .then(() => submit(commitment))
        .catch((error) => {
          console.log(error)
          showErrorMessage(i18n.t('message.requiredFieldsIncomplete'))
        })
    };

    onMounted(async () => {
      getWorkersSupervisedForDropDown()
      getSupervisorsForDropDown()
      show()
    })

    return {
      show,
      submit,
      event,
      commitment,
      workersSupervised,
      supervisors,
      selectedWorker,
      selectedSupervisor,
      isLoading,
      isSubmitting,
      required,
      eventInfo,
      validateAndSubmit,
      isOnline,
      commitment_functionality,
      clientId,
      colors,
    }
  },
}
</script>

<style lang="scss">
.form-group {
  label {
    font-size: 1.2rem;
    font-weight: 400;
  }
}
</style>